body {

  @extend %body;

  // embedded environment
  &.embed {

    background-image: none;
    margin: 5px;

    .container {
      background-color: $bg-custom-color;
      border: 0;
      padding: 15px;
      text-align: left;
      width: 100%;
    }

    .navbar,
    .noembed,
    .hr-primary,
    .comment {
      display: none;
    }

  }

  &.nav {
    padding-top: 59px;
  }

  &.embed,
  &.img {
    text-align: center;
  }

  &.timer {

    &.timer-full {

      background: $bg-timer;
      color: $white;

      .container {
        background: none;
      }

    }

    .dark-wrapper {
      background: theme-color(dark);
      border-radius: $border-radius-lg;
      max-width: 875px;
      @media (max-width: 34em) {
        max-width: 218px;
      }
    }

  }

}

div {

  &.flush {
    margin: 8px 0 0 -1.125rem;
  }

  &.duedate {

    border-left: .25rem solid $gray-300;
    margin: 25px 25px 10px 0;
    padding-left: 25px;

    h5 {
      @extend .text-muted;
    }

  }

  &.block {
    @extend .p-4;
    @extend .border;
    @extend .rounded;
    background: $white;
  }

}

a {
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
}

address {
  abbr {
    margin-right: 5px;
  }
}

hr {

  &.hr-primary {

    background-image: linear-gradient(to right, $transparent, $fade-in, $transparent);
    border: 0;
    color: theme-color(dark);
    height: 1px;
    overflow: visible;
    padding: 0;
    text-align: center;

    &::after {
      background-image: $bg-custom-image;
      content: '§';
      display: inline-block;
      padding: 0 .15em;
      position: relative;
      top: -.8em;
    }

  }

}

blockquote {
  &.simple {
    border-left: 0;
    font-size: $font-size-base;
    margin-left: inherit;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.spaced {
    margin-bottom: 25px;
  }
}


ol,
ul {
  &.spaced {
    li {
      margin: 8px 0;
    }
  }
  &.compact {
    li {
      margin: 0px;
      padding: 0px;
    }
  }
}

table {

  ul,
  ol {
    margin-left: 5px;
    padding-left: 5px;
  }

  &.rubric {
    @extend .table;
    @extend .table-sm;
    @extend .table-bordered;
    @extend .table-hover;
    background-color: $white;
  }

}
