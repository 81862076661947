// placeholders

%body {
  background-image: $bg-custom-image;
  font-size: 18px;
  padding-top: 15px;
}

%icon-default {
  color: theme-color(info);
}

%icon-success {
  color: theme-color(success);
}

%icon-warning {
  color: theme-color(warning);
}

%icon-danger {
  color: theme-color(danger);
}
