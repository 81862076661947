.container {

  @extend .p-4;

  background: $bg-container;
  border-radius: $border-radius;
  margin-top: 50px;
  width: 100%;

  &.narrow {
    max-width: 730px;
  }

  &.max {
    max-width: 90%;
  }

  &.dark {
    background: $gray-900;
    color: $white;
  }

}

.footnote {

  @extend .d-flex;
  @extend .justify-content-between;
  @extend .d-print-none;

  font-size: smaller;
  margin: 25px 0 0;

  a {

    @extend .text-muted;

    &:hover {
      color: $primary
    }

  }

}

.addeventatc {
  margin-left: 10px;
}

.message {
  background: $body-bg;
  border: 1px solid $gray-300;
  border-radius: $border-radius;
  margin: 0 auto;
  max-width: 325px;
  padding: 15px;
}

.notice {
  @extend .message;
  margin-top: 50px;
}

.notice-embed,
.notice-img {
  display: inline-block;
  width: auto;
}

.infobox {
  @include infobox($primary);
}

.infobox-default {
  @include infobox($primary);
}

.infobox-success {
  @include infobox($success);
}

.infobox-warning {
  @include infobox($warning);
}

.infobox-danger {
  @include infobox($danger);
}

.infobox-inactive {
  @include infobox($dark);
}

.shout {

  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-middle;

  div {

    &:nth-child(1) {
      @extend .align-self-center;
    }

    &:nth-child(2) {

      @extend .d-flex;
      @extend .justify-content-center;

      width: 40px;

      .devicon {
        font-size: 325%;
      }

    }

  }

}

.error {

  @extend .alert;
  @extend .alert-danger;

  h4 {
    @extend .alert-heading;
  }

  div {
    @extend .mt-1;
    @extend .mb-0;
  }

  a {
    @extend .alert-link;
  }

}

.src {
  @extend .bg;
  @extend .bg-primary;
  @extend .display-4;
  @extend .mb-4;
  @extend .rounded-3;
  @extend .text-light;
  margin-top: 150px;
  padding: 5px;
}

.list-group {

  a {
    @extend .list-group-item;
    @extend .list-group-item-action;
  }

  .devicons,
  .fa {
    float: left;
  }

}

.youtube {

  @extend .ratio;
  @extend .ratio-16x9;

}

.media-container {
  @extend .text-center;
  margin: 0px auto;
  width: 720px;
  max-width: 100%;
}
