/*

These classes exist in the codebase and have some function (usually as Javascript selector).
But their function is not to describe any CSS property. In oder to be elimininated from the
orphaned classname finder (/js/bustom/classnames.js), they are added as empty classes here.

*/

.g-recaptcha {
  /*! global/script/php/challenge.class.php */
}

.navsys {
  /*! global/script/php/document.class.php */
}

.navselect {
  /*! framework/js/custom/source/helpers.js */
}

.toggle-link,
.toggle-switch,
.toggle-top {
  /*! framework/js/custom/source/navsys.js */
}

.clipboard {
  /*! framework/js/custom/source/copy.js */
}

.countdown {
  /*! framework/js/custom/source/countdown.js */
  /*! global/page/timer.php */
  /*! mymaxwellclass/script/php/course.php */
}

.custom-validation {
  /*! framework/js/custom/validation.js */
}

.atla {
  /*! framework/js/custom/link.js */
}

.tree {
  /*! framework/js/custom/tree.js */
}

.bible {
  /*! mymaxwellclass/page/logos-desktop.php ++ */
}

.iubenda-black,
.iubenda-embed {
  /*! global/page/about.php */
}

.textarea {
  /*! mymaxwellclass/page/nmax.php */
}
