.navbar-course {
  @extend .navbar;
  @extend .navbar-expand-md;
  @extend .navbar-light;
  @extend .fixed-top;
  background-color: $bg-custom-color;
  min-height: 59px;
}

.navmenu-course {
  @extend .navbar-nav;
  @extend .d-flex;
  @extend .justify-content-sm-start;
  width: 100%;
}

.week-select {

  @extend .nav-item;
  @extend .dropdown;
  @extend .align-self-start;
  background-color: $white;
  position: absolute;
  right: 15px;

  > a {
    &:first-child {
      @extend .btn;
      @extend .btn-dark;
      @extend .dropdown-toggle;
    }
  }

}

.week-select-nonav {

  @extend .nav-item;
  @extend .dropdown;
  @extend .justify-content-center;
  background-color: $white;

  > a {
    &:first-child {
      @extend .btn;
      @extend .btn-dark;
      @extend .dropdown-toggle;
    }
  }

}

.scrollable {
  height: auto;
  max-height: 480px;
  overflow-x: hidden;
}

.nav-tour {

  @extend .navbar;
  @extend .fixed-top;
  @extend .navbar-expand-lg;
  @extend .navbar-dark;
  @extend .bg-dark;

  .btn-tour {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-outline-light;
    @extend .text-large;
    @extend .me-2;
    @extend .mt-2;
    @extend .mt-md-0;
    width: 130px;
  }

  .tour-monitor {
    @extend .nav-item;
    @extend .text-light;
    @extend .text-large;
    @extend .align-self-start;
    position: absolute;
    right: 15px;
  }

}
