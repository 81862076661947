.alert {
  display: inline-block;
}

.alert-default {

  @extend .alert-info;

  i {
    @include alert-icon('default');
  }

}

.alert-success {
  i {
    @include alert-icon('success');
  }
}

.alert-warning {
  i {
    @include alert-icon('warning');
  }
}

.alert-danger {
  i {
    @include alert-icon('danger');
  }
}
