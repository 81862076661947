// colors
$transparent: rgba(0, 0, 0, 0);
$fade-in: rgba(0, 0, 0, .75);

// backgrounds
$bg-custom-image: url('/global/image/background.00.png');
$bg-custom-color: #fff;
$bg-container: rgba($bg-custom-color, .9);
$bg-timer: #222526;

// borders
$border-custom: 1px solid rgba($input-border-color, .5);

// cards
$card-shadow-primary: rgba(0, 0, 0, .2);
$card-shadow-secondary: rgba(0, 0, 0, .15);
