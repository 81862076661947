/*************************
    - Basic settings -
*************************/
.circular-countdown * {
	margin:0;
	padding:0;
}

.circular-countdown .time {
    position:relative;
	display:inline-block;
	vertical-align:middle;
}

@media (max-width:1100px) {
	.circular-countdown .time {
		float:none;
	}
}

/*****************
    - Canvas -
*****************/
.circular-countdown canvas.bg {
	position:absolute;	
	z-index:1;
}

.circular-countdown canvas.circle {
	position:relative;
    z-index:2;
}

/****************
    - Count -
****************/
.circular-countdown .count {
    position:absolute;
	z-index:3;
	top:0;
	left:0;
    text-align:center;
    color:#333;
   	font-family:"Open Sans", sans-serif;
	font-weight:600;
	font-size:30px;
	line-height:30px;
}

/***************
    - Text -
***************/
.circular-countdown .text {
    position:absolute;
	z-index:4;
	top:0;
	left:0;
    text-align:center;
    color:#5f5f5f;
   	font-family:"Open Sans", sans-serif;
	font-weight:400;
	font-size:14px;
	line-height:14px;
}

/***************
    - Type -
***************/
/* Blue */
.circular-countdown.blue .time .count,
.circular-countdown.blue .time .text {
	-moz-text-shadow:0px 1px rgba(63,166,195,0.8);
		 text-shadow:0px 1px rgba(63,166,195,0.8);
}

/* Multicolor */
.circular-countdown.multicolor .time {
	background:transparent url(/global/image/bg-multicolor-circle.png) no-repeat top left;
}

.circular-countdown.multicolor .time canvas.bg {
	display:none;
}

/* Multisize */
.circular-countdown.multisize .second {
	background:transparent url(/global/image/bg-multisize-circle.png) no-repeat top left;
}

.circular-countdown.multisize .second canvas.bg {
	display:none;
}

