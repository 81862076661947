// sass-lint:disable no-important

.bg-light {
  background-color: $bg-custom-color;
}

.bg-alarm {
  background-color: $orange !important;
}

.inverse {
  background-color: theme-color(dark);
  color: $white;
}

.text-inactive {
  @extend .text-muted;
}
