.click {

  @extend a !optional;

  text-decoration: none;

  cursor: pointer;

  &.active {
    color: $link-hover-color;
  }

}

.toggle-nav {
  margin-left: 20px;
  margin-top: -10px;
}

.toggle-div {
  @include clearfix;
}
