// custom mixins

@mixin infobox($mode) {

  @extend .card;
  @extend .float-end;
  @extend .d-none;
  @extend .d-md-block;
  @extend .text-center;
  @extend .mt-2;
  @extend .ms-4;
  clear: both;
  white-space: nowrap;

  .card-header {
    background-color: $mode;
    color: $white;
  }

}

@mixin alert-icon($state) {
  @extend %icon-#{$state};
}

@mixin text-colors {
  @each $name, $color in $colors {
    &-#{$name} {
      color: $color;
    }
  }
}
