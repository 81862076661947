// sass-lint:disable no-important, class-name-format, no-vendor-prefixes

$aecolor: #333;
$aehover: #555;
$aeboxshadow: rgba(0, 0, 0, .4);
$aehighlight: rgba(0, 0, 0, 0);

//button
.addeventatc {

  color: $aecolor;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family-sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  text-decoration: none;

  &:hover {
    color: $aehover;
    font-size: 16px;
    text-decoration: underline;
  }

  &:active {
    outline: 0 !important;
  }

  .alarm_reminder,
  .all_day_event,
  .attendees,
  .calname,
  .client,
  .date_format,
  .description,
  .end,
  .facebook_event,
  .location,
  .method,
  .organizer,
  .organizer_email,
  .recurring,
  .start,
  .status,
  .timezone,
  .title,
  .transp,
  .uid {
    display: none !important;
  }

}

// dropdown
.addeventatc_dropdown {
  -moz-border-radius: 3px;
  //-moz-box-shadow: $aeboxshadow 0 10px 26px;
  -webkit-border-radius: 3px;
  //-webkit-box-shadow: $aeboxshadow 0 10px 26px;
  //-webkit-tap-highlight-color: $aehighlight;
  -webkit-user-select: none;
  background: $white;
  box-shadow: $aeboxshadow 0 10px 26px;
  color: $black !important;
  display: none;
  font-family: $font-family-sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 100%;
  margin-left: -1px;
  padding: 6px 0 0;
  position: absolute;
  text-align: left;
  text-decoration: none;
  transform: scale(.98, .98) translateY(5px);
  transition: transform .15s ease;
  width: 230px;
  z-index: -1;
}

.addeventatc_dropdown.topdown 			{transform: scale(.98,.98) translateY(-5px)!important;}
.addeventatc_dropdown span 				{display: block;line-height: 100%;background: #fff;text-decoration: none;cursor: pointer;font-size: 15px;color: #333;font-weight: 600;padding: 14px 10px 14px 55px;margin: -2px 0;}
.addeventatc_dropdown span:hover 		{background-color: #f4f4f4;color: #000;text-decoration: none;font-size: 15px;}
.addeventatc_dropdown em 				{color: #999!important;font-size: 12px!important;font-weight: 400;}
.addeventatc_dropdown .frs a 			{background: #fff;color: #cacaca!important;cursor: pointer;font-size: 9px!important;font-style: normal!important;font-weight: 400!important;line-height: 110%!important;padding-left: 10px;position: absolute;right: 10px;text-align: right;text-decoration: none;top: 5px;z-index: 101;}
.addeventatc_dropdown .frs a:hover 		{color: #999!important;}
.addeventatc_dropdown .ateappleical 	{background: url(/global/image/icon-apple.svg) 18px 40% no-repeat;background-size: 22px 100%;}
.addeventatc_dropdown .ategoogle 		{background: url(/global/image/icon-google.svg) 18px 50% no-repeat;background-size: 22px 100%;}
.addeventatc_dropdown .ateoffice365 	{background: url(/global/image/icon-office365.svg) 19px 50% no-repeat;background-size: 18px 100%;}
.addeventatc_dropdown .ateoutlook 		{background: url(/global/image/icon-outlook.svg) 18px 50% no-repeat;background-size: 22px 100%;}
.addeventatc_dropdown .ateoutlookcom 	{background: url(/global/image/icon-outlookcom.svg) 18px 50% no-repeat;background-size: 22px 100%;}
.addeventatc_dropdown .ateyahoo 		{background: url(/global/image/icon-yahoo.svg) 18px 50% no-repeat;background-size: 22px 100%;}
.addeventatc_dropdown .atefacebook 		{background: url(/global/image/icon-facebook.svg) 18px 50% no-repeat;background-size: 22px 100%;}
.addeventatc_dropdown .copyx 			{height: 21px;display: block;position: relative;cursor: default;}
.addeventatc_dropdown .brx 				{height: 1px;overflow: hidden;background: #e8e8e8;position: absolute;z-index: 100;left: 10px;right: 10px;top: 9px;}
.addeventatc_dropdown.addeventatc-selected {transform: scale(1,1) translateY(0px);z-index: 99999999999;}
.addeventatc_dropdown.topdown.addeventatc-selected {transform: scale(1,1) translateY(0px)!important;}
.addeventatc_dropdown .drop_markup 		{background-color: #f4f4f4;}
