.nowrap {
  white-space: nowrap;
}

.text-large {
  font-size: 150%;
}

.text {
  @include text-colors;
}

:target {
  @extend .fw-bolder;
  @extend .border;
  @extend .border-danger;
  @extend .rounded-1;
  @extend .mark;
}
