i {

  @extend .d-print-none;

  &.middle {
    vertical-align: middle;
  }

}

.course-progress {

  @extend .w-100;
  @extend .py-3;

  .progress {
    height: 20px;
  }

  .progress-bar {
    background-color: $gray-500;
  }

}

.hourglass-spin {

  >[class*='fa-hourglass'] {
    animation: showhide 4s steps(1) infinite;
    opacity: 0;
  }

  >.fa-hourglass-end {

    animation-delay: 2s;

    &.spin {
      animation: showhidespin 4s linear infinite;
    }

  }

  >.fa-hourglass-half {
    animation-delay: 1s;
  }

  >.fa-hourglass-start {
    animation-delay: 0s;
  }

}

@keyframes showhide {

  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

}

@keyframes showhidespin {

  0% { opacity: 0; }

  74.9999999% {
    opacity: 0;
  }

  75% {
    opacity: 1;
    transform: rotate(0deg);
  }

  100% { opacity: 1;
    transform: rotate(180deg);
  }

}

.aimg {

  @extend .float-end;
  @extend .d-none;
  @extend .d-md-inline-block;

  margin-bottom: 25px;
  margin-left: 25px;

  &img {
    @extend .img-fluid;
  }

}

.devicons {
  font-size: 150%;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.fade-cycle-element {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}
