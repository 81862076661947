div {

  &.block {
    background-color: $white;
    border: 1px solid $gray-300;
    border-radius: $border-radius;
    box-shadow: none;
    display: inline-block;
    margin-bottom: 10px;
    padding: 10px;
  }

}
