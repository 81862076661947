.card-group {

  @extend .mb-4;

  :first-child {
    margin-bottom: 0;
  }

}

.card-hover {

  transition: transform .5s;

  &::after {
    box-shadow: 0 8px 17px 0 $card-shadow-primary, 0 6px 20px 0 $card-shadow-secondary;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 2s cubic-bezier(.165, .84, .44, 1);
    width: 100%;
    z-index: -1;
  }

  &:hover,
  &:focus {

    transform: scale3d(1.006, 1.006, 1);

    &::after {
      opacity: 1;
    }

  }

}

.card-tour {
  @extend .card-hover;
  @extend .h-100;
  @extend .mb-3;
  cursor: pointer;
  min-width: 275px;
}
